<!-- //群聊设置 -->
<template>
  <div>
     <div class="rlay__groupSetting flexbox flex-col" style="height:100%;">
       <div class="ntMain__cont flex1 flexbox flex-col">
          <div class="gs__item-member">
        <ul class="flexbox">
         <li><img src="@assets/img/uimg/img-avatar01.jpg" />Andy</li><li><img src="@assets/img/uimg/img-avatar02.jpg" />Cassiel</li>
         <li><img src="@assets/img/uimg/img-avatar06.jpg" />吖吖</li><li><img src="@assets/img/uimg/img-avatar09.jpg" />一起2021</li>
         <li><img src="@assets/img/uimg/img-avatar03.jpg" />Beart</li><li><img src="@assets/img/uimg/img-avatar10.jpg" />杜小丽</li>
         <li><img src="@assets/img/uimg/img-avatar07.jpg" />和服少女</li> <li><img src="@assets/img/uimg/img-avatar11.jpg" />Tom</li>
        <li><img src="@assets/img/uimg/img-avatar05.jpg" />小龙</li><li><img src="@assets/img/uimg/img-avatar08.jpg" />财神爷</li>
        <li><img src="@assets/img/uimg/img-avatar15.jpg" />爱丽丝</li> <li><img src="@assets/img/uimg/img-avatar14.jpg" />北国风光</li>
        </ul>
        <div class="gs__lk-more">查看更多群成员 <i class="iconfont icon-arrD fs-12"></i></div>
       </div>
        <div class="gs__item mb-10">
         <div class="vui__hairline-top"></div>
         </div>
         <div class="gs__item"><label class="lbl">群名</label><div class="cnt"><input class="iptxt" defaultValue="Vue3.0交流小组" /></div>
             </div>
            <div class="gs__item"><label class="lbl">群公告</label> <div class="cnt">各位小伙伴们进入群聊后，记得修改群昵称。格式: 职位+昵称。群内拒绝各种小广告！</div>
              </div>
                <div class="gs__item">
               <label class="lbl">我在本群的昵称</label><div class="cnt"><input class="iptxt" defaultValue="Andy" /></div>
                </div>
             <div class="gs__item mb-10"><div class="vui__hairline-top"></div>
                </div>
                <div class="gs__item"><label class="lbl">显示群成员昵称</label><div class="cnt"><input class="vui__switch fs-18" type="checkbox" checked /></div>
                </div>
             <div class="gs__item"><label class="lbl">消息免打扰</label><div class="cnt"><input class="vui__switch fs-18" type="checkbox" checked /></div>
                </div>
                <div class="gs__item">
              <label class="lbl">置顶聊天</label><div class="cnt"><input class="vui__switch fs-18" type="checkbox" /></div>
                </div>
              <div class="gs__item"> <label class="lbl">保存到通讯录</label>
                <div class="cnt"><input class="vui__switch fs-18" type="checkbox" checked /></div>
             </div>
             <div class="gs__item"> <div class="vui__hairline-top"></div>
            </div>
             <div class="gs__item mb-10"> <div class="cnt c-red align-c" style="cursor:pointer;padding:5px;" @click="handleLogoutChat">删除并退出</div>
             </div>
          </div>
      </div>
 </div>
</template>

<script>
import {reactive, toRefs, inject } from 'vue'
export default {
    components: {},
    setup() {
     const v3layer = inject('v3layer')
        const data = reactive({ onMoute: false,
         topMost: true,
        })
        const handleLogoutChat = () => {
          let $el = v3layer({ type: 'android',content: `确认退出群聊吗？退出后将不再接收群消息！`,
            btns: [
              {text: '取消', style: 'color:#999', click: () => {
                 $el.close()
                 }
                 },{text: '退出', style: 'color:#f57b15'},
              ]
         })
      }
     return {...toRefs(data), handleLogoutChat
   }
 }
}
</script>
